import { create } from 'zustand';

interface Menu {
  bottomNavi: Record<string, number>;
  setScroll: (data: { path: string; scroll: number }) => void;
}

export const useMenuScroll = create<Menu>((set) => ({
  bottomNavi: {
    cate: 0,
    like: 0,
    my: 0,
    home: 0,
  },
  setScroll: (data: { path: string; scroll: number }) => {
    set((state) => ({
      bottomNavi: { ...state.bottomNavi, [data.path]: data.scroll },
    }));
  },
}));
