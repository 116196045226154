import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';

export const _bottomNavigationContainer = styled.nav<{ isShow: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: ${theme.zIndex.sticky};
    display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #fff;
    border-top: 1px solid ${theme.colors.black};
    // safe-area-inset-bottom 값의 60%만 사용
    padding: 4px 8px calc(4px + env(safe-area-inset-bottom));
    min-height: 64px;

    /* iOS 11.0 fallback */
    @supports (padding: constant(safe-area-inset-bottom)) {
        padding-bottom: calc(4px + constant(safe-area-inset-bottom));
    }

    ${mediaUp('mobile')} {
        display: none;
    }
`;

export const _navigationGap = styled.div`
    // 네비게이션 컨테이너와 동일한 높이 유지
    height: calc(64px + (env(safe-area-inset-bottom) * 0.6));

    @supports (padding: constant(safe-area-inset-bottom)) {
        height: calc(64px + (constant(safe-area-inset-bottom) * 0.6));
    }

    ${mediaUp('mobile')} {
        display: none;
    }
`;

export const _navigationItem = styled.button`
    flex: 1;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:active {
        opacity: 0.7;
    }
`;

export const _iconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`;

export const _navigationText = styled.span<{ isActive: boolean }>`
    font-size: 12px;
    font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
    line-height: 1.2;
    color: ${({ isActive }) => (isActive ? '#000' : '#A0A0A0')};
    transition: color 0.2s ease;
`;
