'use client';

import { usePathname } from 'next/navigation';
import { useContext, useEffect, useState } from 'react';
import { ModalContextDispatch } from './modal/modalProvider/ModalContext';
import PrivateTermModal from './term/PrivateTermModal';
import ServiceTermModal from './term/ServiceTermModal';
import { _Footer, _Inner, _RightSection, _CustomerService, _Title, _PhoneNumber, _ServiceInfo, _InfoItem, _Terms, _TermButton, _CompanyInfo, _InfoLine, _BusinessLink, _Copyright, _UpButton, _Disclaimer } from '@/app/styles/components/footer/mainfooter';
import Link from 'next/link';
import useIcon from '@/app/_hook/useIcon';
import { MouseEvent } from 'react';

const Footer = () => {
    const { IcUp } = useIcon();
    const path = usePathname();
    const { addModal } = useContext(ModalContextDispatch);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const isBottomNavHidden = ['/login', '/find/password', '/find/id', '/signUp', '/basket'].includes(path || '');

    useEffect(() => {
        // 클라이언트 사이드에서만 실행되는 모바일 체크
        if (typeof window !== 'undefined') {
            const checkMobile = Boolean(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent));
            setIsMobile(checkMobile);
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 120;
            setShowScrollButton(show);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const isHidden = path === '/orderFinish' || path === '/find/password' || path === '/find/id' || path === '/prlist' || path === '/review' || path === '/review/detail' || path === '/promotion' || path === '/promotion/detail';
    const isOrderPage = path === '/login' || path === '/signUp' || path === '/order' || path === '/basket';
    if (isHidden) return null;

    return (
        <_Footer role="contentinfo" $isOrderPage={isOrderPage}>
            <_Inner>
                <_CompanyInfo>
                    <_Terms>
                        <Link href="/info">
                            <_TermButton>회사소개</_TermButton>
                        </Link>
                        <_TermButton onClick={() => addModal(<ServiceTermModal />)}>이용약관</_TermButton>
                        <_TermButton onClick={() => addModal(<PrivateTermModal />)}>개인정보처리방침</_TermButton>
                    </_Terms>
                    <_InfoLine>
                        <span>상호: (주)이씨에프</span>
                        <span>대표: 송채민</span>
                        <span>전화번호: 1551-0579</span>
                        <span>개인정보취급관리자: 한혁진</span>
                        <span>통신판매신고번호: 2024-서울마포-0871 호</span>
                    </_InfoLine>
                    <_InfoLine>
                        <span>
                            사업자등록번호: 535-81-03201
                            {!isMobile && [
                                ' [',
                                <_BusinessLink key="business-link" href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5358103201" target="_blank" rel="noreferrer noopener">
                                    사업자정보확인
                                </_BusinessLink>,
                                ']',
                            ]}
                        </span>
                        <span>주소: 04050 서울 마포구 양화로 140 9F</span>
                        <span>이메일: ecfinc.korea@gmail.com</span>
                        <span>국민은행 (주)이씨에프 032901-04-371441</span>
                    </_InfoLine>
                    <_Copyright>Copyright © ECF CO.,Ltd. All rights reserved.</_Copyright>
                    <_Disclaimer>일부 상품의 경우 주식회사 ECF는 통신판매의 당사자가 아닌 통신판매중개자로서 상품, 상품정보, 거래에 대한 책임이 제한될 수 있으므로, 각 상품 페이지에서 구체적인 내용을 확인하시기 바랍니다.</_Disclaimer>
                </_CompanyInfo>
                <_RightSection>
                    <_CustomerService>
                        <_Title>고객센터</_Title>
                        <_PhoneNumber>1551-0579</_PhoneNumber>
                        <_ServiceInfo>
                            <_InfoItem>상담시간 10:00 ~ 17:00</_InfoItem>
                            <_InfoItem>점심시간 12:00 ~ 13:00</_InfoItem>
                            <_InfoItem>주말·공휴일 휴무</_InfoItem>
                        </_ServiceInfo>
                    </_CustomerService>
                </_RightSection>
            </_Inner>
            <_UpButton onClick={handleScrollToTop} $show={showScrollButton} $hasBottomNav={!isBottomNavHidden} aria-label="맨 위로 이동">
                <IcUp size={24} color="#000" />
            </_UpButton>
        </_Footer>
    );
};

export default Footer;
