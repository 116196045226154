import { create } from 'zustand';

interface CateTitle {
  title: string;
  setTitle: (data: string) => void;
}

export const useCateTitle = create<CateTitle>((set) => ({
  title: '',
  setTitle: (data: string) => {
    set(() => ({
      title: data,
    }));
  },
}));

interface PrevCategory {
  categoryIndex: number;
  setCategory: (index: number) => void;
}

export const usePrevCategory = create<PrevCategory>((set) => ({
  categoryIndex: 0,
  setCategory: (index: number) => {
    set(() => ({
      categoryIndex: index,
    }));
  },
}));
