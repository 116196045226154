'use client';

import { useMenuScroll } from '@/store/routeScroll';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { usePrevCategory } from '@/store/category';
import { useEffect, useState } from 'react';
import { hasCookie } from '@/api/cookie';
import { _bottomNavigationContainer, _navigationGap, _navigationItem, _iconWrapper, _navigationText } from '@/app/styles/components/footer/bottomNavi';
import useIcon from '@/app/_hook/useIcon';

const BottomNavigation = () => {
    const { IcCateOff, IcCateOn, IcHeartOff, IcHeartOn, IcHomeOff, IcHomeOn, IcMyOff, IcMyOn } = useIcon();

    const router = useRouter();
    const pathName = usePathname();
    const { setScroll } = useMenuScroll();
    const { setCategory } = usePrevCategory();
    const [user, setUser] = useState('');

    const isHiddenPath = ['/login', '/find/password', '/find/id', '/signUp', '/basket', '/order'].includes(pathName || '');

    const handleRoute = (path: string) => {
        setScroll({ path: pathName || '', scroll: window.scrollY });
        router.push(path);
    };

    const handleCategoryClick = () => {
        setCategory(0);
        router.push('/category');
    };

    const handleLikeClick = () => {
        handleRoute(user === 'on' ? '/my/like?type=products' : '/login');
    };

    const handleMyPageClick = () => {
        handleRoute(user === 'on' ? '/my' : '/login');
    };

    useEffect(() => {
        const isUser = hasCookie('accessToken') ? 'on' : 'off';
        setUser(isUser);
    }, []);

    const navigationItems = [
        {
            path: '/category',
            label: '카테고리',
            icon: (active: boolean) => (active ? <IcCateOn size={32} color="#000" /> : <IcCateOff size={32} color="#C8C8C8" />),
            onClick: handleCategoryClick,
        },
        {
            path: '/',
            label: '홈',
            icon: (active: boolean) => (active ? <IcHomeOn size={32} color="#000" /> : <IcHomeOff size={32} color="#C8C8C8" />),
            onClick: () => handleRoute('/'),
        },
        {
            path: '/my/like',
            label: '찜',
            icon: (active: boolean) => (active ? <IcHeartOn size={32} color="#000" /> : <IcHeartOff size={32} color="#C8C8C8" />),
            onClick: handleLikeClick,
        },
        {
            path: '/my',
            label: '마이',
            icon: (active: boolean) => (active ? <IcMyOn size={32} color="#000" /> : <IcMyOff size={32} color="#C8C8C8" />),
            onClick: handleMyPageClick,
        },
    ];

    return (
        <>
            <_bottomNavigationContainer isShow={!isHiddenPath}>
                {navigationItems.map((item) => {
                    const isActive = pathName === item.path;
                    return (
                        <_navigationItem key={item.path} onClick={item.onClick} type="button">
                            <_iconWrapper>{item.icon(isActive)}</_iconWrapper>
                            <_navigationText isActive={isActive}>{item.label}</_navigationText>
                        </_navigationItem>
                    );
                })}
            </_bottomNavigationContainer>
            {!isHiddenPath && <_navigationGap />}
        </>
    );
};

export default BottomNavigation;
